//Work detail scrollbar

function refreshDetailScroll() {
    var bottomOffset = $(document).height() - $(this).scrollTop() - $(window).height();
    var fixedHeight = $(window).height() - $("head").height() - 290 + bottomOffset;
    if($(window).width() > 641) {
        if (bottomOffset < 120) {
            $(".arrows").css("bottom", 145 - bottomOffset);
            $(".detail-text .fixed").css("height", fixedHeight);
        }
        else {
            $(".arrows").css("bottom", "");
            $(".detail-text .fixed").css("height", "auto");
        }
    } else {
        if (bottomOffset < 120) {
            $(".arrows").css("bottom", 130 - bottomOffset);
        }
        else {
            $(".arrows").css("bottom", "");
        } 
    }
};
$(window).scroll(refreshDetailScroll);
$(window).on("refreshScroll", refreshDetailScroll);


$(document).ready(function () {
    //$('.owl-carousel.news-wrapper').owlCarousel();

    $('#nav-toggle-button').click(function () {
        $(this).toggleClass('active');
        $('.main-nav').toggle();
    });

    lightbox.option({
        'alwaysShowNavOnTouchDevices': true,
        'disableScrolling': true,
        'albumLabel':  	"Obrázek %1 z %2"
      })

});